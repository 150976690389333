import React from "react"
import styled from "styled-components"

import { SecondLevelHeader } from "./SectionHeader"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing[10]};

  @media (min-width: 800px) {
    margin-bottom: ${props => props.theme.spacing[13]};
  }

  @media (min-width: 1100px) {
    flex-direction: ${props => (props.isReverse ? "row-reverse" : "row")};
  }
`

const TextSection = styled.div`
  flex: 1 0 auto;
  margin-bottom: ${props => props.theme.spacing[7]};

  p {
    line-height: 1.4;
    font-size: 1.2rem;

    @media (min-width: 800px) {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  @media (min-width: 1100px) {
    flex: 0 0 40%;
    margin-bottom: 0;
    padding-right: ${props => (props.isReverse ? 0 : props.theme.spacing[7])};
    padding-left: ${props => (props.isReverse ? props.theme.spacing[7] : 0)};
  }
`

const ImageSection = styled.div`
  flex: 1 0 auto;
  position: relative;

  img {
    max-width: 100%;
    width: 100%;
    box-shadow: ${props => props.theme.shadows.content};
  }

  &:after {
    content: "";
    width: 80%;
    height: 80%;
    right: ${props => (props.isReverse ? "initial" : "-2rem")};
    left: ${props => (props.isReverse ? "-2rem" : "initial")};
    bottom: -2rem;
    background: ${props =>
      props.bgColor ? props.bgColor : props.theme.colors.gray[2]};
    display: block;
    position: absolute;
    z-index: -1;
  }

  @media (min-width: 1100px) {
    flex: 0 0 60%;
    margin-top: -${props => props.theme.spacing[5]};
    padding-left: ${props => (props.isReverse ? 0 : props.theme.spacing[7])};
    padding-right: ${props => (props.isReverse ? props.theme.spacing[7] : 0)};
  }
`

const Reference = ({ heading, children, reverse, image, alt, color }) => {
  return (
    <Wrapper isReverse={reverse ? true : false}>
      <TextSection isReverse={reverse ? true : false}>
        <SecondLevelHeader>{heading}</SecondLevelHeader>
        {children}
      </TextSection>
      <ImageSection isReverse={reverse ? true : false} bgColor={color}>
        <img src={image} alt={alt} />
      </ImageSection>
    </Wrapper>
  )
}

export default Reference
