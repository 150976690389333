import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/Hero"
import { PeopleHunt } from "../../components/PeopleHunt"
import { Contact } from "../../components/Contact"
import SEO from "../../components/seo"
import ReferenceContainer from "../../components/ReferenceContainer"
import Reference from "../../components/Reference"
import { ThirdLevelHeader } from "../../components/SectionHeader"

import Image1 from "../../images/digitalexperience_bromollabolagen.jpg"
import Image2 from "../../images/digitalexperience_do.jpg"

const DigitalExperience = () => {
  const tags = [
    "Digital formgivning",
    "CMS",
    "Episerver",
    "Umbraco",
    "Sanity",
    "Wordpress",
    "O365",
    "Sharepoint",
    "Figma",
    "Headless CMS",
    "React",
    ".NET",
    "Progressive Web Apps (PWA)",
    "Java",
    "GatsbyJS",
    "Next.js",
    "PHP",
    "Hemsidor",
    "Webbapplikationer",
    "Intranät",
    "Tillgänglighetsanpassning",
  ]

  return (
    <Layout>
      <SEO title="Digital Experience" />
      <Hero
        heading={`Digital Experience`}
        goto="references"
        scrollTitle="Digital Experience"
      />
      <ReferenceContainer>
        {/* <Reference
          heading="Kundcase #1"
          image={Image1}
          alt="Bild på två av Bromöllabolagens nya hemsidor"
          color="#FE6F61"
        >
          <p>
            Det digitala avtrycket som krävs i dagens samhälle ställer nya
            utmaningar där vi måste vara anpassningsbara, ta hänsyn till flera
            parametrar och där enbart tekniken är en del av leveransen.
          </p>
          <p>
            Våra erbjudanden täcker in helheten för era behov, går att kombinera
            och den know-how vi besitter utgör skillnaden från våra
            konkurrenter.
          </p>
        </Reference>
        <Reference
          heading="Kundcase #2"
          reverse
          image={Image2}
          alt="Bild på Diskrimineringombudsmannens hemsida"
          color="#B0E4EF"
        >
          <p>
            Det digitala avtrycket som krävs i dagens samhälle ställer nya
            utmaningar där vi måste vara anpassningsbara, ta hänsyn till flera
            parametrar och där enbart tekniken är en del av leveransen.
          </p>
          <p>
            Våra erbjudanden täcker in helheten för era behov, går att kombinera
            och den know-how vi besitter utgör skillnaden från våra
            konkurrenter.
          </p>
        </Reference>
        <Reference heading="Kundcase #3">
          <p>
            Det digitala avtrycket som krävs i dagens samhälle ställer nya
            utmaningar där vi måste vara anpassningsbara, ta hänsyn till flera
            parametrar och där enbart tekniken är en del av leveransen.
          </p>
          <p>
            Våra erbjudanden täcker in helheten för era behov, går att kombinera
            och den know-how vi besitter utgör skillnaden från våra
            konkurrenter.
          </p>
        </Reference> */}
      </ReferenceContainer>
      <PeopleHunt />
      <Contact />
    </Layout>
  )
}

export default DigitalExperience
